import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="frontpage" className="page_container">
          <div className="headerOuterWrapper">
            <div className="innerWrapper">
              <div className="dataWrapper">
                <div id="block-block-2" className="block block-block">
                  <div className="content">
                    <p>
                      <a title="Logo" className="siteLogo" href="/">
                        <img
                          alt="Migraleve"
                          src="/sites/migraleve.co.uk/files/images/logo.jpg"
                        />
                      </a>
                    </p>
                  </div>
                </div>
                <div className="trigger">
                  {" "}
                  <a>
                    <img src="/sites/migraleve.co.uk/files/images/menu_arrow.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="navOuterWrapper">
          <div className="innerWrapper">
            <div className="dataWrapper1">
              <div id="block-menu-primary-links" className="block block-menu">
                <div className="content">
                  <ul className="menu">
                    <li className="leaf first home">
                      <a href="/home" title="Home">
                        <img src="/sites/migraleve.co.uk/files/images/home.png" />
                      </a>
                    </li>
                    <li className="expanded active-trail aboutmigraines">
                      <a
                        href="/about-migraine"
                        title="About Migraines"
                        className="about-migraine"
                      >
                        ABOUT MIGRAINES
                      </a>
                      <ul className="menu">
                        <li className="leaf first migrainesymptoms">
                          <a
                            href="/migraine-symptoms"
                            title="Migraine Types & Symptoms"
                          >
                            Migraine Types & Symptoms
                          </a>
                        </li>
                        <li className="leaf potentialmigrainecauses">
                          <a
                            href="/migraine-causes"
                            title="Potential Migraine Causes"
                          >
                            Potential Migraine Causes
                          </a>
                        </li>
                        <li className="leaf last active-trail whatcanyoudoaboutit">
                          <a
                            href="/migraine-treatment-and-prevention"
                            title="Treatment and Relief"
                            className="active"
                          >
                            Treatment and Relief
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="leaf ourproducts">
                      <a href="/migraine-relief-products" title="Our Products">
                        OUR
                        <br /> PRODUCTS
                      </a>
                    </li>
                    <li className="leaf migrainemanagementtips">
                      <a href="/migraine-tips" title="Migraine Management Tips">
                        MIGRAINE MANAGEMENT TIPS
                      </a>
                    </li>
                    <li className="leaf migrainetriggerdiary">
                      <a
                        href="/migraine-trigger-diary"
                        title="Migraine Trigger Diary"
                      >
                        MIGRAINE TRIGGER DIARY
                      </a>
                    </li>
                    <li className="leaf last wheretobuy">
                      <a href="/where-to-buy" title="Where to buy">
                        WHERE TO BUY
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contentOuterWrapper">
          <div className="innerWrapper">
            <div className="dataWrapper">
              <div className="homeBannerWrapper">
                <div className="headingwrapper">
                  <div className="titlewrapper">
                    <h1>TREATMENT AND RELIEF</h1>
                    <h4 className="subheading">
                      <strong>
                        If you suffer from frequent or severe migraine symptoms, you should see a GP.{" "}
                      </strong>
                      You may need to try several different types of treatments before finding the most effective one.
                    </h4>
                  </div>
                  <div className="imagewrapper">
                    <img src="/sites/migraleve.co.uk/files/images/treatment-relief.jpg" />
                  </div>
                </div>

                <div className="innerPageContentWrapper">
                  <div className="innerPageContent">
                    <div id="node-12" className="node">
                      <div className="content clear-block">
                        <p>
                          Generally, treatment for migraine includes lifestyle changes,  Acute treatment, or preventative treatment
                        </p>
                        <p>Acute treatment such as over the counter painkillers, migraine specific treatments (triptans) and anti-sickness medication. These treatments aim to help manage the symptoms when an attack comes on. You do need to be careful not to take certain acute treatments too many days a month as this can cause{" "}<a href="https://migrainetrust.org/understand-migraine/types-of-migraine/medication-overuse-headache/" target="_blank"> {" "} medication overuse headache</a>. For example, triptans shouldn’t be taken on more than 8-10 days per month.</p>
                        <p>Preventive treatment such as beta-blockers, tricyclic antidepressants or anti-epilepsy drugs. These treatments aim to reduce how often you have migraine attacks and how bad they are. You take them everyday and build them up to an effective dose. Some people need to try a range of treatments, or may be referred to a{" "}<a href="https://migrainetrust.org/live-with-migraine/healthcare/migraine-clinics/" target="_blank"> {" "} headache specialist</a> if the treatments don’t work for them.</p>
                        <h2>Lifestyle changes</h2>
                        <p>
                          Alongside the treatments many people benefit from reviewing whether{" "}<a href="https://migrainetrust.org/live-with-migraine/self-management/common-triggers/" target="_blank"> {" "} lifestyle changes</a> can help with their migraine attacks. This can include things like sticking to a routine, waking up at the same time each da, practicing good sleep hygiene, eating regularly and at similar times each day, not getting hungry and staying hydrated. Taking gentle exercise, such as walking and maintaining a healthy lifestyle can help. If there are factors such as alcohol or lack of sleep that seem to trigger your migraine attacks, there might be changes that you can make to help reduce how often you have attacks.
                        </p>

                        <h2>Over the counter medicines</h2>
                        <p>
                          <strong>Simple Painkillers</strong>, This is medication that treats the pain of a migraine attack at the time they are taken and includes paracetamol and non-steroidal anti-inflammatory drugs (NSAIDs) such as ibuprofen. It is medication that does not have opioids such a codeine in it. Painkillers that contain opioids are best avoided in migraine as benefits may be short-lived and they can be addictive.
                        </p>
                        <p>
                          <strong>Anti-sickness</strong>, This is medication that treats sickness (nausea and vomiting) that some people get with migraine attacks. They are also known as ‘anti-sickness’ medication.
                        </p>
                        <p>During migraine attacks, gut absorption slows and medication absorption may be improved when taken with an anti-sickness medicine. Two anti-emetics, metoclopramide and domperidone, also help your body absorb the analgesics. Metoclopramide is associated with side effects (resulting in symptoms very like Parkinson’s disease, which is reversible) and should be avoided in children and young adults.
                          Anti-emetics should be taken before or at the same time as any analgesics you are taking for the migraine attack.</p>
                        <p>
                          <strong>Triptans</strong>, This is medication that was designed to treat migraine attacks. They reduce the pain and sickness of a migraine attack at the time they are taken.
                        </p>
                        <p>Triptans are very safe when used appropriately by the people for whom they are prescribed. You should take them at the start of the head pain. If a triptan works, but the pain comes back you can usually take a second dose within 24 hours. As with all medication triptans can have side effects. Common side effects include nausea; jaw, neck or chest tightness, pressure or squeezing; rapid heart rate; fatigue; numbness or tingling (especially involving the face); or a burning sensation over the skin. If side effects are intolerable, or if their effects trouble you, you should always contact your doctor for review.
                          People with coronary heart disease, cerebrovascular disease, peripheral vascular disease, uncontrolled hypertension, and acute vascular conditions should not take them.</p>
                        <p>
                          <strong>Gepants</strong>, These are calcitonin gene-related peptide (CGRP) receptor antagonists. Compared to conventional acute treatments such as triptans, non-steroidal anti-inflammatory drugs (NSAIDs), and other painkillers, the gepants don’t seem to cause rebound or medication overuse headache. As such, they may be useful as a preventive treatment when taken regularly.
                        </p>
                        <p>It is a new treatment and currently being reviewed for use on the NHS.</p>
                        <p>
                          <strong>Combination treatments</strong>, It is possible to combine a triptan with a NSAID especially for people with longer lasting attacks or those in whom migraine attacks tend to recur. Several combination medicines are available. The most commonly used contain aspirin or paracetamol combined with codeine, caffeine and/or an antiemetic. Paracetamol is generally not effective for migraine attacks but may be combined in some treatments to improve the effectiveness e.g. Migraleve, Migramax. However, it is best to avoid codeine and opiates in migraine treatment as their benefits can be short term, with dependency and medication overuse headache risks being high.
                        </p>
                        <p>
                          You can buy a number of combination medicines for migraine without a prescription at your local pharmacy. If you’r enot sure which one I sbest for you, ask your pharmacist.
                        </p>
                        <p>
                          For more information about treatment, please refer to
                          the NHS website{" "}
                          <a
                            href="https://www.nhs.uk/conditions/migraine/treatment/"
                            target="_blank"
                          >
                            {" "}
                            here
                          </a>
                        </p>
                      </div>
                      <div className="clear-block">
                        <div className="meta"></div>
                      </div>
                    </div>
                  </div>
                  <div className="innerPagePromo">
                    <div id="block-block-5" className="block block-block">
                      <div className="content">
                        <div className="migrane_tips">
                          <div className="inner">
                            <img
                              alt
                              src="/sites/migraleve.co.uk/files/images/ma.png"
                            />
                            <div>
                              <h2>tips</h2>
                              <p>
                                Get some useful tips to help prevent a migraine
                                attack.
                              </p>
                              <a title="Tips" href="/tips">
                                Find out more
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="buying_migraleve">
                          <div className="inner">
                            <img
                              alt
                              src="/sites/migraleve.co.uk/files/images/bm.png"
                            />
                            <div>
                              <h2>
                                buying migraleve<sup>™</sup>
                              </h2>
                              <p>
                                Find your nearest stockist of Migraleve
                                products.
                              </p>
                              <a title="Where to buy" href="/where-to-buy">
                                Where to buy
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="trigger_diaryBg">
                          <div className="inner">
                            <img
                              alt
                              src="/sites/migraleve.co.uk/files/images/td.png"
                            />
                            <div>
                              <h2>trigger diary</h2>
                              <p>
                                Want to find out what triggers your migraines?
                              </p>
                              <a
                                title="Try a trigger diary"
                                href="/trigger-diary"
                              >
                                Try a trigger diary
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="info-section">
                  <div className="row">
                    <div className="col">
                      <div className="thumbnail">
                        <img src="/sites/migraleve.co.uk/files/images/symptoms.jpg" />
                        <div className="content">
                          <h2 className="text-normal">
                            Symptoms of a Migraine
                          </h2>
                          <p>
                            Read more about the phases of a migraine, and common
                            symptoms.
                          </p>
                          <a
                            href="/migraine-symptoms"
                            className="learnmore btn-light"
                          >
                            Learn More <span className="float-right"> > </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="thumbnail">
                        <img src="/sites/migraleve.co.uk/files/images/causes.jpg" />
                        <div className="content">
                          <h2 className="text-normal">
                            Potential Migraine Causes
                          </h2>
                          <p>Read more about what can trigger a migraine.</p>
                          <a
                            href="/migraine-causes"
                            className="learnmore btn-light"
                          >
                            Learn More <span className="float-right"> > </span>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col">
                      <div className="thumbnail">
                        <img src="/sites/migraleve.co.uk/files/images/trigger_diary.jpg" />
                        <div className="content">
                          <h2 className="text-normal">
                            Migraine Trigger Diary
                          </h2>
                          <p>
                            Track your migraines to gain an understanding of
                            your triggers.
                          </p>
                          <a
                            href="/migraine-trigger-diary"
                            className="learnmore btn-light"
                          >
                            Learn More <span className="float-right"> > </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footerOuterWrapper">
          <div className="innerWrapper">
            <div className="dataWrapper">
              <div className="footerContent">
                <div
                  id="block-menu-menu-site-footer-menu"
                  className="block block-menu"
                >
                  <div className="content">
                    <ul className="menu">
                      <li className="leaf first privacypolicy">
                        <a href="/privacy-policy" title="Privacy policy">
                          Privacy policy
                        </a>
                      </li>
                      <li className="leaf legalnotice">
                        <a href="/legal-notice" title="Legal notice">
                          Legal notice
                        </a>
                      </li>
                      <li className="leaf contactus">
                        <a href="/contact-us" title="Contact us">
                          Contact us
                        </a>
                      </li>
                      <li className="leaf cookiepolicy">
                        <a href="/cookie-policy" title="Cookie Policy">
                          Cookie Policy
                        </a>
                      </li>
                      <li className="leaf modernslaveryactstatement">
                        <a
                          href="/sites/migraleve.co.uk/files/pdf/MSA_JnJ_Limited_May_2024.pdf"
                          target="_blank"
                        >
                          Modern Slavery Act Statement
                        </a>
                      </li>
                      <li className="leaf last cookiesettings">
                        <a id="ot-sdk-btn" className="ot-sdk-show-settings">
                          Cookie Settings
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div id="block-block-3" className="block block-block">
                  <div className="content">
                    <p>
                      <strong>
                        Always read the label. Ask your pharmacist for advice.
                      </strong>
                    </p>
                    <p>
                      MIGRALEVE<em>™</em> contains paracetamol, buclizine and
                      codeine. MIGRALEVE<em>™</em> Pink contains paracetamol,
                      codeine and buclizine. MIGRALEVE<em>™</em> Yellow contains
                      paracetamol and codeine.
                    </p>
                    <p>
                      Can cause addiction. For three days use only. This
                      medicine can make you feel sleepy. Do not drive while
                      taking this medicine until you know how it makes you feel.
                    </p>
                    <p>
                      This site is published by Johnson &amp; Johnson Limited
                      which is solely responsible for its contents. It is
                      intended for a UK audience. MIGRALEVE<em>™</em> is a
                      registered trademark.
                    </p>
                    <p>
                      This site is best viewed with the following web browsers:
                      Internet Explorer version 8, Firefox version 2+, Safari
                      3+, Chrome version 3+.
                    </p>
                    <p>
                      © Johnson &amp; Johnson Limited 2024. This site was last
                      updated on: 15<sup>th </sup>August 2024
                    </p>
                  </div>
                </div>
                <p>
                  <a
                    id="popup-trigger"
                    href="/sites/migraleve.co.uk/files/pop-up-text"
                    rel="lightmodal"
                  >
                    hide this
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Page;
